import { sortByOptions } from 'constants/sortByOptions';
import { ensureSortByIsSet } from 'redux/modules/search/actions/helpers';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { UPDATESEARCH } from 'redux/modules/search/actions/types';
import { clearSearch } from 'redux/modules/search/actions/clear-search';
import { clearLocations } from 'redux/modules/content/actions/clear-locations';
import { fetchComponentsAndProducts } from 'redux/modules/search-and-browse/actions/fetch-components-and-products-with-recommendations';

const sizeDefault = 48;

export const searchProductsByCategory = ({ params: rawParams, refinement }) => {
  const params = ensureSortByIsSet(rawParams, sortByOptions.browse);

  return (dispatch, getState) => {
    dispatch(clearSearch(refinement));

    if (!refinement) {
      dispatch(clearLocations());
    }

    dispatch({
      type: UPDATESEARCH,
      params,
    });

    const orderId = getCustomerOrderId(getState());
    const request = {
      name: 'Browse',
      body: {
        customerSearchRequest: {
          queryParams: {
            size: sizeDefault,
            ...params,
            orderId,
          },
        },
      },
    };

    return dispatch(
      fetchComponentsAndProducts({
        request,
      }),
    );
  };
};
