import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { sortByOptions } from 'constants/sortByOptions';
import urls from 'constants/urls';
import {
  NLP_CAROUSEL_DESKTOP_TITLE,
  NLP_CAROUSEL_MOBILE_TITLE,
  NLP_CAROUSEL_VIEW_ALL_LINK,
  NLP_CAROUSEL_VIEW_ALL_TITLE,
} from 'constants/nlp-carousel';
import usePrevious from 'hooks/use-previous';

import { GA_GLP_RECOMMENDED_CAROUSEL_TYPE } from 'utils/clickContext/recommendedCarouselContext';

import BrowseHeader from 'components/BrowseHeader';
import EmptyCategory from 'components/ProductGrid/Empty/Category';
import ContentLocation from 'components/ContentLocation';
import NotFound from 'components/NotFound/NotFound';
import ProductGrid from 'components/ProductGrid';
import ProductsRefinements from 'components/Product/Refinements';
import Skeleton from 'components/Skeleton';
import RecommendedCarousel from 'components/RecommendedCarousel';
import { canShowRecommended } from 'components/RecommendedCarousel/canShowRecommended';
import { getFeatureFlags } from 'utils/feature-flags';
import { colourScallopGrey } from '@johnlewispartnership/wtr-ingredients/foundations/colours';
import BrowseMetaHeader from './MetaHeader';
import GroceriesLegalNotice from './GroceriesLegalNotice';

import styles from './Browse.scss';

const Browse = ({
  category,
  locationsLoading,
  productsLoading,
  location,
  customerSlotBranchId,
  fetchProducts,
  recommendations,
  hasSEOContent,
  isPageLoading,
}) => {
  const branchId = customerSlotBranchId || 'no_branch';
  const previousCustomerSlotBranchId = usePrevious(branchId);

  useEffect(() => {
    if (previousCustomerSlotBranchId && previousCustomerSlotBranchId !== branchId) {
      fetchProducts(category);
    }
  }, [previousCustomerSlotBranchId, branchId, fetchProducts, category]);

  const categoryNotFound = category === null;

  if (categoryNotFound) {
    return <NotFound />;
  }

  const { pathname = '' } = location;
  const groceriesHome = pathname.toLowerCase() === urls.groceriesHome;
  const headerLabel = groceriesHome
    ? {
        display: true,
        text: 'Browse by category',
      }
    : null;

  const { glpNLPCarousel, glpWithRecommendations } = getFeatureFlags();

  const showRecommended = glpWithRecommendations && canShowRecommended(recommendations);

  const categoryLoading = category === undefined;

  const pageLoading = categoryLoading || locationsLoading || isPageLoading;

  return (
    <Fragment>
      <BrowseMetaHeader location={location} />
      {pageLoading && (
        <Skeleton withSpinner spinnerPosition="top" height="100vh" data-testid="category-loading" />
      )}
      {!pageLoading && (
        <Fragment>
          <div className={styles.browseHeader}>
            <BrowseHeader linksLabel={headerLabel} location={location} isGLP={groceriesHome} />
            <ContentLocation name="header" preloadImages />
          </div>
          {showRecommended && (
            <div className={styles.recommendationsWrapper}>
              <div
                className={classNames('container-fluid', {
                  [styles.nlpRecommendationsWrapper]: glpNLPCarousel,
                })}
                data-testid="rec-prod-carousel"
              >
                <RecommendedCarousel
                  products={recommendations}
                  titleDesktop={glpNLPCarousel ? NLP_CAROUSEL_DESKTOP_TITLE : 'You might like'}
                  titleMobileAndTablet={
                    glpNLPCarousel ? NLP_CAROUSEL_MOBILE_TITLE : 'You might like'
                  }
                  className={styles.chosenForYou}
                  typeForAnalytics={GA_GLP_RECOMMENDED_CAROUSEL_TYPE}
                  viewAllLink={glpNLPCarousel ? NLP_CAROUSEL_VIEW_ALL_LINK : undefined}
                  viewAllTitle={glpNLPCarousel ? NLP_CAROUSEL_VIEW_ALL_TITLE : undefined}
                  green={glpNLPCarousel}
                />
              </div>
            </div>
          )}
          <ProductsRefinements hideCategoryFilter sortOptions={sortByOptions.browse} />
          {productsLoading ? (
            <Skeleton
              withSpinner
              spinnerPosition="top"
              height="100vh"
              backgroundColor={colourScallopGrey}
              data-testid="products-loading"
            />
          ) : (
            <ProductGrid Empty={EmptyCategory} />
          )}
          {hasSEOContent && (
            <div data-testid="seoContent" className={styles.seoContent}>
              <ContentLocation name="seoContent" />
            </div>
          )}
          {groceriesHome && <GroceriesLegalNotice />}
        </Fragment>
      )}
    </Fragment>
  );
};

Browse.displayName = 'Browse';

Browse.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.shape({})),
  customerSlotBranchId: PropTypes.string,
  fetchProducts: PropTypes.func.isRequired,
  category: PropTypes.shape({
    id: PropTypes.string,
  }),
  locationsLoading: PropTypes.bool.isRequired,
  productsLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({}),
  }),
  hasSEOContent: PropTypes.bool.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
};

Browse.defaultProps = {
  recommendations: [],
  customerSlotBranchId: undefined,
  category: undefined,
  location: {
    from: null,
    pathname: '',
    state: {
      data: {},
    },
  },
};

export default Browse;
